import { Injectable } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { FormGroup } from '@angular/forms';
import { constants, environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { Role } from '../_models/role';
import { QUOTE_STAGES } from '../constants';

@Injectable({ providedIn: 'root' })
export class HelperService {
  today = new Date();

  public shuttersCheckboxes = [
    { key:'cutting', label: 'Cutting'  },
    { key:'assembly', label: 'Assembly' },
    { key:'tilt_bar', label: 'Tilt bar' },
    { key:'arches', label: 'Arches' },
    { key:'framing', label: 'Framing' },
    { key:'painting', label: 'Painting' },
    { key:'final_inspection', label: 'Hinging/Final Inspection' },
  ];

  public typeColor = {
    'retail' : "springgreen",
    'wholesale' : "red"
  }
  public typeRetail = 'retail';
  public typeWholesale = 'wholesale';

  constructor(private authService: AuthenticationService) { }

  numberFormat(num): number {
    var calculation = (Math.round(num * 100) / 100).toFixed(2);
    return parseFloat(calculation);
  }

  getDocumentStatus(signdocument, completed?) {
    var status = '';
    var flag = false;
    if (signdocument == null) {
      status = 'Pending';
    } else {
      if (signdocument.sign_status == 'no') {
        status = 'In Progress';
      } else {
        status = 'Completed';
        flag = true;
      }
    }
    if (completed) {
      return flag;
    } else {
      return status;
    }
  }

  checkPaidAmount(paidAmount) {
    if (paidAmount != undefined && paidAmount != null) {
      if (paidAmount > 0) {
        return true;
      }
    }
    return false;
  }

  getPaymentStatus(status) {
    var status_name = '';
    if (status == 0) {
      status_name = 'Pending';
    } else if (status == 1) {
      status_name = 'Completed';
    }
    // else if (status == 2) {
    //   status_name = 'Completed';
    // }
    return status_name;
  }

  getFinalMeasurement(signdocuments, payment_status, quotestages, paid_count) {
    if (
      this.getDocumentStatus(signdocuments, 'Completed') &&
      // (payment_status == '1' || payment_status == '2') &&
      (quotestages != null && quotestages?.job_stage_key == QUOTE_STAGES.contract)
    ) {
      // if (paid_count != undefined && paid_count != null) {
        return true;
      // }
    }
    return false;
  }

  getAdobeDocLink(id) {
    return environment.API_URL + 'adobe-contract-pdf/' + id;
  }

  getSheetIdfromSlug(sheet) {
    var sheet_id;
    if (sheet == 'roller-shade' || sheet == 'roller-shade-commission') {
      sheet_id = 1;
    } else if (sheet == 'epoxy-garage' || sheet == 'epoxy-garage-commission') {
      sheet_id = 2;
    } else if (sheet == 'carpet-installation' || sheet == 'carpet-installation-commission') {
      sheet_id = 3;
    } else if (sheet == 'plantation-shutter') {
      sheet_id = 4;
    } else if (sheet == 'blinds' || sheet == 'blind-commission') {
      sheet_id = 5;
    } else if (sheet == 'custom-roller-shade' || sheet == 'custom-roller-shade-commission') {
      sheet_id = 6;
    } else if (sheet == 'custom-roman-shade' || sheet == 'custom-roman-shade-commission') {
      sheet_id = 7;
    } else if (sheet == 'honeycomb-shade' || sheet == 'honeycomb-shade-commission') {
      sheet_id = 8;
    } else if (sheet == 'engineered-floor' || sheet == 'engineered-floor-commission') {
      sheet_id = 9;
    } else if (sheet == 'windows-replacement' || sheet == 'windows-replacement-commission') {
      sheet_id = 10;
    } else if (sheet == 'perfect-sheer-shade' || sheet == 'perfect-sheer-shade-commission') {
      sheet_id = 11;
    }

    return sheet_id;
  }

  getJobInProgress(sheet_id) {
    var url;
    if (sheet_id == 1) {
      url = 'roller-shade';
    } else if (sheet_id == 2) {
      url = 'epoxy-garage';
    } else if (sheet_id == 3) {
      url = 'carpet-installation';
    } else if (sheet_id == 4) {
      url = 'plantation-shutter';
    } else if (sheet_id == 5) {
      url = 'blinds';
    } else if (sheet_id == 6) {
      url = 'custom-roller-shade';
    } else if (sheet_id == 7) {
      url = 'custom-roman-shade';
    } else if (sheet_id == 8) {
      url = 'honeycomb-shade';
    } else if (sheet_id == 9) {
      url = 'engineered-floor';
    } else if (sheet_id == 10) {
      url = 'windows-replacement';
    } else if (sheet_id == 11) {
      url = 'perfect-sheer-shade';
    }
    return url;
  }

  getContractUrl(sheet_id) {
    var url;
    if (sheet_id == 1) {
      url = 'roller-shade-contract';
    } else if (sheet_id == 2) {
      url = 'epoxy-garage-contract';
    } else if (sheet_id == 3) {
      url = 'carpet-installation-contract';
    } else if (sheet_id == 4) {
      url = 'plantation-shutter-contract';
    } else if (sheet_id == 5) {
      url = 'blind-contract';
    } else if (sheet_id == 6) {
      url = 'custom-roller-shade-contract';
    } else if (sheet_id == 7) {
      url = 'custom-roman-shade-contract';
    } else if (sheet_id == 8) {
      url = 'honeycomb-shade-contract';
    } else if (sheet_id == 9) {
      url = 'engineered-floor-contract';
    } else if (sheet_id == 10) {
      url = 'windows-replacement-contract';
    } else if (sheet_id == 11) {
      url = 'perfect-sheer-shade-contract';
    }
    return url;
  }

  getContractPdf(sheet_id) {
    var url;
    if (sheet_id == 1) {
      url = 'roller-shade-contract-pdf';
    } else if (sheet_id == 2) {
      url = 'epoxy-garage-contract-pdf';
    } else if (sheet_id == 3) {
      url = 'carpet-installation-contract-pdf';
    } else if (sheet_id == 4) {
      url = 'plantation-shutter-contract-pdf';
    } else if (sheet_id == 5) {
      url = 'blinds-contract-pdf';
    } else if (sheet_id == 6) {
      url = 'custom-roller-shade-contract-pdf';
    } else if (sheet_id == 7) {
      url = 'custom-roman-shade-contract-pdf';
    } else if (sheet_id == 8) {
      url = 'honey-comb-shade-contract-pdf';
    } else if (sheet_id == 9) {
      url = 'engineered-floor-contract-pdf';
    } else if (sheet_id == 10) {
      url = 'windows-replacement-contract-pdf';
    } else if (sheet_id == 10) {
      url = 'perfect-sheer-shade-contract-pdf';
    }
    return url;
  }


  checkIsContract(response) {
    if (response.contract_name != null) {
      return true;
    }
    return false;
  }

  getOrderNumber(customerList, order_number_array) {
    var firstLetter = customerList.cus_first_name[0].toUpperCase();
    var lastName = customerList.cus_last_name.toUpperCase();
    return (
      order_number_array[0] +
      '-' +
      firstLetter +
      lastName +
      '-' +
      order_number_array[1] +
      '-' +
      order_number_array[2]
    );
  }

  getNewOrderNumber(customerList, order_number_array, date) {
    var firstLetter = customerList.cus_first_name.toUpperCase();
    var lastName = customerList.cus_last_name.toUpperCase();
    date = this.orderNumberDateFormat(date);
    return (
      firstLetter +
      lastName +
      '-' +
      order_number_array[1] +
      order_number_array[2] +
      '-' +
      order_number_array[0] +
      date
    );
  }

  orderNumberDateFormat(date) {
    var format = '';
    if (date != null) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      format = month + day + year;
    }

    return format;
  }

  disabledDates() {
    return (current: Date): boolean =>
      differenceInCalendarDays(current, this.today) > 0;
  }

  findArray(id, array) {
    var itemName;
    array.forEach((item) => {
      if (id == item.id) {
        itemName = item;
      }
    });
    return itemName;
  }

  solarTreeSelector(solar_addons) {
    var solarAddons = [];
    solar_addons.forEach((item) => {
      if (item.id < 3) {
        solarAddons.push({
          title: item.solar_part_name,
          value: item.amount,
          key: item.id,
          isLeaf: true,
        });
      } else {
        if (item.id == 3) {
          solarAddons.push({
            title: item.solar_part_name,
            value: item.amount,
            key: item.id,
            children: [],
          });
        } else {
          solarAddons[2]['children'].push({
            title: item.solar_part_name,
            value: item.amount,
            key: item.id,
            isLeaf: true,
          });
        }
      }
    });
    return solarAddons;
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('form .ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }

  /*getGeneralInfo() {
    const general = JSON.parse(localStorage.getItem('general'));
    let const_general = {
      address: "",
      phone: "",
      company: "",
      fax: ""
    };
    if (general != undefined) {
      const_general = {
        address: general.address ? general.address : "",
        phone: general.phone ? general.phone : "",
        company: general.company_name ? general.company_name : "",
        fax: general.fax ? general.fax : "",
      };
    }
    return const_general;
  }*/

  getGeneralInfo() {
    let sales_tax = 8.25;
    const general = JSON.parse(localStorage.getItem('company'));
    //console.log("getGeneralInfo general",general)
    let const_general = {
      address: "",
      phone: "",
      company: "",
      fax: "",
      logo: "",
      is_sales_tax: 0,
      sales_tax: sales_tax
    };
    if (general != undefined) {
      const_general = {
        address: general.address ? general.address : "",
        //phone: general.phone ? general.phone : "",
        phone: general.phone_line ? general.phone_line : "",
        company: general.name ? general.name : "",
        fax: general.fax ? general.fax : "",
        logo: general.logo_url ? general.logo_url : "",
        is_sales_tax: general.is_sales_tax ? general.is_sales_tax : 0,
        sales_tax: sales_tax
      };
    }
    return const_general;
  }

  getContractImages(img_url) {
    return environment.BASE_URL + img_url;
  }

  getProfitPercentage(all_profit_percentage, percent) {
    var profit_percentage: any = [];
    Object.keys(all_profit_percentage).forEach((key) => {
      if (percent == all_profit_percentage[key]['percent']) {
        profit_percentage = all_profit_percentage[key];
      }
    });
    return profit_percentage;
  }

  //get Search Time Customer List
  fatchsearchCustomerList(value: string, customerOptionList) {
    let found = [];
    const listOfOption: Array<{
      value: string;
      text: string;
      // status: string;
      type:string;
    }> = [];
    if (value) {
      customerOptionList.filter(function (element) {
        if (element.cus_full_name.toLowerCase().includes(value.toLowerCase())) {
          found.push(element);
        }
      });

      found.forEach((item) => {
        listOfOption.push({
          value: item.pkCus,
          text: item.cus_full_name,
          // status: item.cust_status,
          type: item.cust_type
        });
      });
    }
    return listOfOption;
  }

  fatchOrderNumber(customer_id, customerOptionList, order_number_array) {
    var order_number_value = '';
    var customer = customerOptionList.find((x) => x.pkCus == customer_id);
    if (customer_id) {
      order_number_value = this.getOrderNumber(customer, order_number_array);
    }
    return order_number_value;
  }

  fetchNewOrderNumber(
    customer_id,
    customerOptionList,
    order_number_array,
    date
  ) {
    var order_number_value = '';
    var customer = customerOptionList.find((x) => x.pkCus == customer_id);
    if (customer_id) {
      order_number_value = this.getNewOrderNumber(
        customer,
        order_number_array,
        date
      );
    }
    return order_number_value.replace(/\s/g, "");
  }

  // Compare Selected Value Option
  compareItem = (o1: any, o2: any) =>
    o1 && o2 ? JSON.stringify(o1) === JSON.stringify(o2) : o1 === o2;

  //Change Customer Status Green Color To Springgreen
  getBackgroundColor(color) {
    if (color == 'green') {
      color = 'springgreen';
    }
    return color;
  }

  findInArray(data, array, key) {
    return data ? array.find((x) => x[key] == data[key]) : null;
  }

  getGeneral() {
    // general: {
    //   address: '323 Hilltop Ave, Richardson, TX 75081',
    //   phone: 'Phone: (972) 464-9793',
    //   company: 'STARWOOD DISTRIBUTORS',
    //   fax: '214.341.5200 Fax',
    // },
    return JSON.parse(localStorage.getItem('general'));
  }

  findPermission = (permission_name: string) => {
    const AuthUserData = this.authService.getLocalAuthData();
    let flag = false;
    if (AuthUserData != undefined) {
      if (AuthUserData.roleName == Role.Sales) {
        AuthUserData['permissions'].forEach((pd: any) => {
          if (pd.name == permission_name) {
            flag = true;
          }
        });
      } else {
        flag = true;
      }
    }
    return flag;
  }

  searchData(array: any, string: string) {
    let flag: boolean = false;
    let index: number = -1;
    array.filter((element, key) => {
      if (element.toLowerCase() == string.toLowerCase()) {
        flag = true;
        index = key;
      }
    });
    return {flag ,index};
  }

  getSalesTax(amount){
    let sales_tax = 0
    if(this.getGeneralInfo().is_sales_tax) {
      sales_tax = amount * (this.getGeneralInfo().sales_tax / 100);
    }
    return sales_tax;
  }
}
