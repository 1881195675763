import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';
import adminmenu from '../../menu/adminmenu.json';
import { Role } from '../../_models/role';
import { Permissions } from 'src/app/constants';
import {HelperService} from "../../service/helperservice.service";

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.scss'],
})
export class DashboardSidebarComponent implements OnInit {
  public superadminmenu: {}[] = adminmenu;
  public menu: any;
  isCollapsed = false;
  permissions = Permissions;

  companies = null;
  selectedCompany = null;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    public helper: HelperService,
  ) {
    this.menu = this.superadminmenu;
  }

  ngOnInit(): void {
    this.companies = JSON.parse(localStorage.getItem('companies'));
    const storedCompany = localStorage.getItem('company');

    if(this.companies && this.companies.length > 0 && storedCompany){
      this.selectedCompany = this.companies.find(
        company => company.id === JSON.parse(storedCompany)?.id
      );
    }
    console.log("DashboardSidebarComponent companies",this.companies);
    console.log("DashboardSidebarComponent selectedCompany",this.selectedCompany);

    this.setMenu();
    if (window.innerWidth <= 1024) {
      this.isCollapsed = true;
    }

  }

  logout() {
    this.authService.logout().subscribe(
      (res: any) => {
        if (res.status) {
          this.authService.clearLocalStorage();
          this.router.navigate(['/', 'auth', 'login']);
        }
      },
      (error) => { }
    );
  }

  // Set Menu of Sidebar
  setMenu() {
    const AuthUserData = this.authService.getLocalAuthData();
    this.authService.getAuthUser().subscribe((res) => {
      if (res.status) {
        localStorage.setItem('userData', JSON.stringify(res.data.user));
        if(res.data.user.is_admin_role){
          localStorage.setItem('companies', JSON.stringify(res.data.all_companies))
        }
      }
    });

    if (AuthUserData) {
      if (AuthUserData.roleName == Role.Sales) {
        // this.menu = adminmenu;
        this.menu = this.createMenu(adminmenu, AuthUserData['permissions']);
      } else if (AuthUserData.roleName == Role.Admin) {
        this.menu = adminmenu;
      } else {
        this.authService.clearLocalStorage();
        this.router.navigate(['/', 'auth', 'login']);
      }
    } else {
      this.authService.clearLocalStorage();
      this.router.navigate(['/', 'auth', 'login']);
    }
  }

  createMenu = (menuitem: any, permissions: any) => {
    const menudata: any = [];
    const menu_not_show = ['customer', 'sales person']
    menuitem.forEach((element: any, main_key) => {
      const permission = element.permission_name;

      if (!Object.keys(element.sub_menu).length) {
        if (menu_not_show.find(x => x != permission)) {
          if (permission != 'dashboard') {
            if (permission != "") {
              permissions.forEach((pd: any) => {
                if (pd.name == permission) {
                  menudata.push(element);
                }
              });
            } else {
              menudata.push(element);
            }
          } else {
            menudata.push(element);
          }
        }
      } else {
        let sub_menu: any = element;
        if (sub_menu.permission_name != this.permissions.view_settings) {
          var removeIndex = [];
          Object.values(sub_menu.sub_menu.sub_menu_list.sub_main_menu).forEach((sub_element: any, key) => {
            const sub_permission = sub_element.permission_name;

            if (sub_permission != "" && sub_permission != undefined) {
              let is_sub_main_menu_permission = false;
              if (permissions.length > 0) {
                permissions.forEach((pd: any) => {
                  if (pd.name == sub_permission) {
                    is_sub_main_menu_permission = true;
                  }
                });
              }

              if (!is_sub_main_menu_permission) {
                removeIndex.push(key);
              }
            }
          });

          for (var i = removeIndex.length - 1; i >= 0; i--) {
            sub_menu.sub_menu.sub_menu_list.sub_main_menu.splice(removeIndex[i], 1);
          }
          menudata.push(sub_menu);

        } else {
          let is_sub_menu_list_permission = false;
          permissions.forEach((pd: any) => {
            if (pd.name == permission) {
              is_sub_menu_list_permission = true;
            }
          });
          if (is_sub_menu_list_permission) {
            menudata.push(sub_menu);
          }
        }
      }
    });

    return menudata;
  }

  isEmptyObject(obj: Object) {
    return obj && Object.keys(obj).length === 0;
  }

  checkNzSelected(array) {
    if (array != undefined && array != null) {
      let url = this.router.url.split('/')[1];
      if ((this.router.url.split('/')[2]) != undefined && (this.router.url.split('/')[2]) != null) {
        if (!this.isNumber(this.router.url.split('/')[2])) {
          url += "/" + this.router.url.split('/')[2];
        }
      }
      // console.log({array});
      // console.log({url});

      if (array.indexOf(url) !== -1) {
        return true;
      }
    }

    return false;
  }

  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  selectCompany(event){
    console.log("selectCompany event",event);
    //console.log("selectCompany router  url",this.router.url);
    this.selectedCompany=event;

    this.router.navigate(['/dashboard']).then(success => {
      if (success || this.router.url === '/dashboard') {
        localStorage.setItem('company', JSON.stringify(this.selectedCompany));
        location.reload();
      } else {
        console.error("Navigation to /dashboard failed");
      }
    });
  }

}
